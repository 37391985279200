import {Module} from 'vuex';
import type {RootState} from '..';
import ApiService from '@/services/api.service';
import {datadogRum} from '@datadog/browser-rum';

export interface BookingState {
  templates: any[];
  locations: any[];
  isLoading: boolean;
}

function logCustomError(errorMessage: string, errorDetails: object) {
  datadogRum.addError(errorMessage, {
    error: errorDetails,
  });

  if (process.env.NODE_ENV !== 'production') {
    // tslint:disable-next-line:no-console
    console.error(errorMessage, errorDetails);
  }
}

export const booking: Module<BookingState, RootState> = {
  namespaced: false,
  state: {
    templates: [],
    locations: [],
    isLoading: false,
  },
  getters: {
    bookingLoading: (state) => state.isLoading,
    bookingTemplates: (state) => state.templates,
    bookingLocations: (state) => state.locations,
  },
  mutations: {
    setLoading: (state, isLoading: boolean ) => {
      state.isLoading = isLoading;
    },
    setTemplates: (state, { templates }: { templates: any[]}) => {
      state.templates = templates;
    },
    setLocations: (state, { locations }: { locations: any[]}) => {
      state.locations = locations;
    },
  },
  actions: {
    async LOAD_TEMPLATES({commit}) {
      try {
        commit('setLoading', true);
        const endpoint = process.env.VUE_APP_BOOKING_TEMPLATES ?? 'https://d1bf4497u6px8a.cloudfront.net/baa04/booking.json';
        fetch(endpoint, {
          mode: 'cors',
        }).then((response) => response.json())
          .then((data) => {
            commit('setTemplates', { templates: data.templates });
            commit('setLocations', { locations: data.locations });
          }).finally(() => commit('setLoading', false));
        return true;
      } catch (e) {
        logCustomError('LOAD_TEMPLATES ERROR', { error: e });
      }

    },
    async SAVE_APPOINTMENT_DETAILS(_, payload) {
      try {
        const { data } = payload;
        const { data: { booking: Booking } } = await ApiService.customRequest({
          method: 'post',
          url: `booking/details/save`,
          data,
        });

        return Booking;
      } catch (e) {
        logCustomError('SAVE_APPOINTMENT_DETAILS ERROR', { error: e });
        return false;
      }

    },
    async LOAD_BOOKING(_, payload) {
      try {
        const { bookingUUID } = payload;
        const { data: { booking: Booking } } = await ApiService.customRequest({
          method: 'get',
          url: `booking/${bookingUUID}`,
        });

        return Booking;
      } catch (e) {
        logCustomError('LOAD_BOOKING ERROR', { error: e });
        return false;
      }

    },
    async BOOK_APPOINTMENT(_, payload) {
      try {
        const { data } = payload;
        const { data: { booking: Booking } } = await ApiService.customRequest({
          method: 'post',
          url: `booking/drchrono/create`,
          data,
        });

        return Booking;
      } catch (e) {
        logCustomError('BOOK_APPOINTMENT ERROR', { error: e });
        return false;
      }

    },
    async SAVE_INSURANCE(_, payload) {
      try {
        const { data } = payload;
        const { data: { booking: Booking } } = await ApiService.customRequest({
          method: 'post',
          url: `booking/insurance/save`,
          data,
        });

        return Booking;
      } catch (e) {
        logCustomError('SAVE_INSURANCE ERROR', { error: e });
        return false;
      }

    },
    async LOAD_SLOTS_FOR_DOCTOR({commit}, payload) {
      try {
        commit('setLoading', true);
        const { data } = payload;
        const { data: { slots } } = await ApiService.customRequest({
          method: 'post',
          url: `booking/doctor/slots`,
          data,
        });

        commit('setLoading', false);
        return slots;
      } catch (e) {
        logCustomError('LOAD_SLOTS_FOR_DOCTOR ERROR', { error: e });
        return false;
      }

    },
    async LOAD_DOCTOR_BIO(_, payload) {
      try {
        const { externalId } = payload;
        const { data: { doctor_bio } } = await ApiService.customRequest({
          method: 'get',
          url: `booking/doctor/${externalId}/bio`,
        });
        return doctor_bio;
      } catch (e) {
        logCustomError('LOAD_DOCTOR_BIO ERROR', { error: e });
        return false;
      }

    },
    async LOAD_INSURANCES(_, payload) {
      try {
        const { params, cancelToken } = payload;
        const { data: { insurances } } = await ApiService.customRequest({
          method: 'get',
          url: `booking/insurances/list`,
          params,
          cancelToken,
        });
        return insurances;
      } catch (e) {
        logCustomError('LOAD_INSURANCES ERROR', { error: e });
        return false;
      }

    },
    async LOAD_INSURANCE_PLANS(_, payload) {
      try {
        const { foreignId } = payload;
        const { data: { insurance_plans } } = await ApiService.customRequest({
          method: 'get',
          url: `booking/insurances/${foreignId}/plans`,
        });
        return insurance_plans;
      } catch (e) {
        logCustomError('LOAD_INSURANCE_PLANS ERROR', { error: e });
        return false;
      }

    },

  },
};
