export const BOOKING_SCREENS = {
  CHOOSE_APPT_TYPE: 'CHOOSE_APPT_TYPE',
  CHOOSE_DOCTOR: 'CHOOSE_DOCTOR',
};

export const BOOKING_SERVICES_NAMES = {
  MASSAGE: 'Massage Therapy',
};

export const BOOKING_SERVICES = [
  {
    id: 1,
    name: 'Chiropractic',
    img: require('@/assets/images/chiropractic.png'),
    paymentType: 'Insurance',
    infoText:
      'Chiropractic care is a safe, effective and natural approach to managing pain and other health-related issues. We invite you for a complimentary consultation today.',
  },
  {
    id: 2,
    name: 'Occupational Therapy',
    img: require('@/assets/images/occupational_therapy.png'),
    paymentType: 'Insurance',
    infoText:
      'Occupational therapy is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.',
  },
  {
    id: 3,
    name: 'Physical Therapy',
    img: require('@/assets/images/phisical_therapy.png'),
    paymentType: 'Insurance',
    infoText:
      'Physical therapy is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.',
  },
  {
    id: 4,
    name: 'Acupuncture',
    img: require('@/assets/images/Acupuncture.png'),
    paymentType: 'Insurance',
    infoText:
      'Acupuncture is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.',
  },
  {
    id: 5,
    name: 'Massage Therapy',
    img: require('@/assets/images/MassageTherapy.png'),
    paymentType: 'Cash',
    infoText:
      'Massage therapy is a form of therapy for those recuperating from physical or mental illness that encourages rehabilitation through the performance of activities required in daily life.',
  },
];
